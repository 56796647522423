<template>
  <button class="tab" :class="{ active: active }" type="button">
    <div class="wrap">
      <BaseIcon class="icon" :name="icon" />
      <span class="text">{{ content.text }}</span>

      <span v-if="value" class="value">{{ value }}</span>
      <span v-else-if="state" class="value"
        ><BaseIcon class="state" :name="state"
      /></span>
    </div>
  </button>
</template>

<script lang="ts" setup>
import type { AtomsIconText } from '../../../../../models/Atoms';

const props = defineProps<{
  content: AtomsIconText;
  value?: number;
  state?: string;
  active: boolean;
}>();

const icon = computed(() => {
  return props.active ? 'ion:close' : props.content.icon;
});
</script>

<style src="./Tab.scss" scoped lang="scss"></style>
