<template>
  <div class="input-range">
    <span v-if="label" class="label">{{ label }}</span>

    <div class="container">
      <div class="wrap">
        <small class="small">{{ displayFn(minModel) }}</small>

        <InputRangeSlider
          v-model:min-model="minModel"
          v-model:max-model="maxModel"
          :min="min"
          :max="max"
        />

        <small class="small">{{ displayFn(maxModel) }}</small>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  label?: string;
  min: number;
  max: number;
  displayFn: (value: number) => string;
}>();

const model = defineModel({ default: [0, 100] });

const minModel = ref<number>(model.value[0]);
const maxModel = ref<number>(model.value[1]);

watch([minModel, maxModel], ([min, max]) => {
  model.value = [min, max];
});
</script>

<style src="./Range.scss" scoped lang="scss"></style>
