<template>
  <div class="distance">
    <div class="input">
      <InputText
        v-model="location"
        :placeholder="
          $t(
            'components.pageheader.search.item.dropdown.filter.distance.input-text.placeholder'
          )
        "
        icon="ion:search"
        :disabled="disabled"
        @input="open = true"
      />

      <button type="button" class="button" @click="handleClick">
        <ButtonBase :content="{ icon: 'ion:locate', text: '' }" />
      </button>
    </div>

    <PageheaderSearchItemDropdownFilterDistanceAutoSuggest
      v-if="open"
      v-click-outside="() => (open = false)"
      :content="{ items: sortedSuggestions }"
      @select="handleSelection"
    />

    <!--    <Input-Checkbox-->
    <!--      :label="-->
    <!--        $t(-->
    <!--          'components.pageheader.search.item.dropdown.filter.distance.input-checkbox.label'-->
    <!--        )-->
    <!--      "-->
    <!--    />-->
  </div>
</template>

<script lang="ts" setup>
import type { BaseLocation } from '../../../../../../../models/BaseLocation';
import type {
  AllNullable,
  Nullable,
} from '../../../../../../../models/CustomUtilityTypes';
import reverseGeoCodeCoordinates from '../../../../../../../utils/reverseGeoCodeCoordinates';
import type { LocationItem, LocationType } from './models';

const model = defineModel<
  AllNullable<{
    id: number;
    type: LocationType;
    name: string;
    latitude: number;
    longitude: number;
  }>
>();

defineProps<{
  disabled?: boolean;
}>();

const location = ref<string>(useSearchStore().state.locationName || '');

const open = ref<boolean>(false);

const handleSelection = (data: LocationItem) => {
  open.value = false;
  location.value = data.label!;
  model.value = {
    id: data.id,
    type: data.type,
    name: data.label,
    latitude: undefined,
    longitude: undefined,
  };
};

const suggestions = await (async (): Promise<LocationItem[]> => {
  const { locations } = await fetchAutosuggestLocations();

  const transform = (items: Nullable<BaseLocation[]>) => {
    return (
      items
        ?.map((item) => ({
          type: item._entityType as LocationType,
          label: item.i18nName,
          id: item.id,
        }))
        .filter((item) => item.type && item.label && item.id) || []
    );
  };

  const mergedLocations = [
    ...transform(locations.value?.addressPoiGroups),
    ...transform(locations.value?.locations),
    ...transform(locations.value?.regions),
  ];

  return mergedLocations;
})();

const sortedSuggestions = computed(() => {
  return suggestions
    .map((suggestion) => ({
      ...suggestion,
      score: sortUtils(location.value, suggestion.label!),
    }))
    .sort((a, b) => b.score - a.score);
});

const handleClick = async () => {
  try {
    const currentUserPosition = await getCurrentUserLocation();
    const displayName = await reverseGeoCodeCoordinates(currentUserPosition);

    location.value = displayName;
    model.value = {
      id: undefined,
      type: undefined,
      name: displayName,
      latitude: currentUserPosition.latitude,
      longitude: currentUserPosition.longitude,
    };
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'Unknown error occurred';
    console.error(`${errorMessage}`);
  }
};
</script>

<style src="./Distance.scss" scoped lang="scss"></style>
